<template>
  <div class="me-view">
    <div class="container-wrapped">
      <p>This is a paragraph.</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Me',
  components: {}
});
</script>

<style scoped lang="scss">
.me-view {
}
</style>
